/**
 * External Dependencies
 */
import 'jquery';
// Cookie Consent
/* require('./modules/CookieConsent'); */

// Offset html anchor for fixed header
/* require('./modules/anchor-scroll'); */

// Observe an element
require('./modules/observer.js');

$(document).ready(() => {
  $('.burger-menu-link').click(function() {
    if (
      $(this)
        .parent()
        .find('.nav-drop')
        .eq(0)
        .hasClass('show')
    ) {
      $(this)
        .parent()
        .find('.nav-drop')
        .removeClass('show');
    } else {
      $(this)
        .parent()
        .find('.nav-drop')
        .eq(0)
        .addClass('show');
    }
  });
  $('li.menu-item-158').click(function() {
    $(this).toggleClass('menu-transform');
    $(this)
      .find('.nav-drop')
      .eq(0)
      .toggleClass('show');
  });
  $('a.menu-item-158 ').click(function(e) {
    e.preventDefault();
  });
});
