// Startseiten Header Change Menu on view

// var url = window.location.href;

function removeClassesWithTimeout(menuContent, header) {
  // FEHLER wenn innerhalb der 1000ms wieder intersected wird
  setTimeout(function() {
    menuContent.classList.remove('active');
    header.classList.remove('active');
    menuContent.classList.remove('transform');
  }, 1000);
}

//if (document.location.pathname === '/') {}
let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.4,
};

// eslint-disable-next-line
  let callback = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        header.classList.contains('active') == false &&
        menuContent.classList.contains('active') == false &&
        menuContent.classList.contains('transform') == false
      ) {
        menuContent.classList.remove('transform');
        header.classList.add('transform');
        menuContent.classList.add('active');
        header.classList.add('active');
      }
    } else {
      menuContent.classList.add('transform');
      header.classList.remove('transform');
      removeClassesWithTimeout(menuContent, header);
    }
  });
};

let menuContent = document.querySelector('.menu-content');
let header = document.querySelector('.site-header');
// eslint-disable-next-line
  let observer = new IntersectionObserver(callback, options);
let target = document.querySelector('.wp-block-cover');
observer.observe(target);
